import React from 'react';
import { css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import Container from '@material-ui/core/Container';

import Layout from '../components/layout';
import SEO from '../components/helpers/seo';

import mq from '../styles/mq';

import { ThemeType } from '../styles/theme';

const DonneesPersonnelles = () => {
  const { color } = useTheme<ThemeType>();

  return (
    <Layout footer>
      <SEO title="Protection des données personnelles (Politique de confidentialité)" />
      <Container>
        <section
          css={css`
            padding: 150px 0;
            min-height: 100vh;

            h2 {
              font-size: 24px;
              margin-bottom: 25px;

              ${mq(`md`)} {
                font-size: 30px;
              }
            }

            p {
              line-height: 1.75;
              margin-bottom: 20px;

              &:last-of-type {
                margin-bottom: 50px;
              }

              & + ul {
                margin-top: -30px;
              }
            }

            ul {
              margin-bottom: 50px;
            }
          `}
        >
          <h1
            css={css`
              color: ${color.accent};
              font-size: 30px;
              font-weight: 900;
              margin-bottom: 50px;
              text-align: center;

              ${mq(`xs`)} {
                font-size: 40px;
              }

              ${mq(`sm`)} {
                font-size: 50px;
              }

              ${mq(`md`)} {
                font-size: 60px;
                text-align: left;
              }
            `}
          >
            Protection des données personnelles (Politique de confidentialité)
          </h1>
          <div>
            <h2>IDENTITE ET RESPONSABLE DU TRAITEMENT</h2>
            <p>
              L’ASBL « LA 3D » est responsable du traitement des données
              personnelles de ses clients (Utilisateurs). Le siège social du
              responsable dudit traitement est sis à 1457 Walhain, Rue de
              Blanmont(NSV) 7. Le responsable du traitement est joignable par
              courriel à l’adresse suivante la3d.beer@gmail.com.
            </p>
          </div>
          <div>
            <h2>FINALITE DU TRAITEMENT</h2>
            <p>
              Dans le cadre de la promotion et la vente de ses produits, l’ASBL
              « LA 3D » est amenée à traiter les données personnelles du client
              (Utilisateur). Le traitement des données du client a pour unique
              finalité de traiter les commandes en ligne des clients et de
              promouvoir nos produits/offres/events auprès de notre clientèle.
            </p>
          </div>
          <div>
            <h2>DESTINATAIRES DES DONNEES</h2>
            <p>
              Les données récoltées dans le cadre de l’exécution de nos missions
              ont des destinataires :
            </p>
            <ul
              css={css`
                list-style: initial;
                padding-left: 20px;
              `}
            >
              <li>l’ASBL « LA 3D » ;</li>
              <li>
                Nos partenaires qui assurent la livraison de nos produits ;
              </li>
              <li>
                Le site partenaire de commande en ligne « fr.shopify.be » qui
                gère le suivi des commandes.
              </li>
            </ul>
          </div>
          <div>
            <h2>DUREE DE CONSERVATION DES DONNEES </h2>
            <p>
              Lorsque des données sont transmises via le formulaire de contact,
              celles-ci sont conservées pour une durée indéterminée.{' '}
            </p>
            <p>
              Cependant, toute personne a la possibilité de contacter l’ASBL «
              LA 3D » par courriel la3d.beer@gmail.com afin de solliciter la
              suppression de leurs données personnelles. Dès réception de la
              demande de suppression, l’ASBL « LA 3D » veillera à ne plus
              adresser de newsletters.
            </p>
            <p>
              Le client a également la possibilité de solliciter de l’ASBL « LA
              3D » la liste des données personnelles transmises via le
              formulaire de contact.
            </p>
          </div>
          <div>
            <h2>RECOURS</h2>
            <p>
              Si le client estime que ses droits au respect du traitement de ses
              données personnelles ont été violés, il a la possibilité
              d’introduire une réclamation auprès de la Commission de la
              protection de la vie privée.
            </p>
          </div>
        </section>
      </Container>
    </Layout>
  );
};

export default DonneesPersonnelles;
